import React from 'react'
import { Link } from 'gatsby'

function Breadcrumbs(props) {
  const { title } = props
  return (
    <div>
      <ul className="breadcrumbs">
        <li className="breadcrumbs-item">
          <Link to="/">Home</Link>
        </li>

        <li className="breadcrumbs-item">
          <span>/ </span>
          {title}
        </li>
      </ul>
    </div>
  )
}

export default Breadcrumbs
